import * as S from "./Services.styles";

export const Services = () => {
  return (
    <S.ServicesWrapper id="services">
      <S.ServiceTitle>Serviços</S.ServiceTitle>
      <S.CardsWrapper>
        <S.Card>
          <S.CardHeader>
            <h4>Atendimento online</h4>
          </S.CardHeader>
          <S.CardBody>
            <h5>
              O atendimento psicológico online oferece conveniência,
              acessibilidade e conforto para cuidar da saúde mental.
              <br /> <br />
              Assim, efetuando uma prática mais humanizada e eficaz para sua
              saúde mental atendendo as necessidades de cada paciente.
              <br /> <br />
              Essa modalidade proporciona o suporte aos pacientes de/ em
              qualquer lugar, apenas acompanhado de um aparelho eletrônico,
              internet, fones de ouvido e um ambiente silencioso. Os
              atendimentos são realizados pela plataforma do Google meet, que é
              uma plataforma segura de comunicação, com duração de 50 min cada.
            </h5>
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardHeader>
            <h4>Atendimento presencial</h4>
          </S.CardHeader>
          <S.CardBody>
            <h5>
              O atendimento psicológico presencial oferece uma experiência
              personalizada e acolhedora para cuidar da saúde mental.
              <br />
              <br />
              Com o contato direito os pacientes vivenciam uma prática mais
              conectada com a profissional e um ambiente seguro e confortável de
              autocuidado.
              <br />
              <br />
              Dessa forma, garantido uma escuta individualizada e eficaz para o
              bem- estar emocional. Os atendimentos presenciais são realizados
              no edf. Premium Office, Mangabeiras em Maceió, com duração de 50
              min cada.
            </h5>
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardHeader>
            <h4>Avaliação Psicologica</h4>
          </S.CardHeader>
          <S.CardBody>
            <h5>
              A avaliação psicológica oferece insights profundos e
              personalizados para compreender melhor a mente humana.
              <br />
              <br />A avaliação psicológica também proporciona um atendimento
              especializado para a investigação do caso, levando em consideração
              o contexto psicosocioemocional do paciente.
            </h5>
          </S.CardBody>
        </S.Card>
      </S.CardsWrapper>
    </S.ServicesWrapper>
  );
};
