import styled from "styled-components";

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  position: relative;
  box-shadown-top: none;

  h2 {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--primary);
    margin-bottom: 1rem;
    font-family: colabero;
  }
  @media (min-width: 425px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const InfoBodyWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;

  h4 {
    color: var(--primary);
    font-weight: 400;
  }
  @media (min-width: 768px) {
    padding: 0 20%;
  }
`;

export const WhatsAppButton = styled.a`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: var(--alternative);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const ColoredLine = styled.div`
  width: 80%;
  height: 2px;
  background: linear-gradient(
    135deg,
    rgba(224, 184, 139, 1) 15%,
    rgba(130, 68, 39, 1) 52%,
    rgba(135, 171, 152, 1) 100%
  );
  position: absolute;
  zindex: -1;
  top: 9%;
`;
