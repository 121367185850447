import * as S from "./Banner.styles";
import { MdWhatsapp } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import bannerImage from '../../images/banner.svg';

export const Banner = () => {
  return (
    <S.BannerWrapper>
      <S.LeftContent>
        <S.BannerTitle>
          Aprenda a lidar
          <br /> com seus
          <br /> pensamentos.
        </S.BannerTitle>
        <S.BannerSubTitle>Áurea Gomes | Psicóloga Clínica</S.BannerSubTitle>
        <S.BannerBody>
          Entre em contato para saber mais sobre os atendimentos, valores e
          agendar uma consulta
        </S.BannerBody>
        <S.BannerButton target="_blank" href="https://wa.link/netv6h">
          <MdWhatsapp size={28} />
          Entre em contato
        </S.BannerButton>
        <S.PhoneWrapper>
          <HiOutlinePhone />
          <S.BannerBody>(82)993416300</S.BannerBody>
        </S.PhoneWrapper>
      </S.LeftContent>
      <S.BannerImage
        src={bannerImage}
        alt="Aurea Banner"
        className="imageBanner"
      />
    </S.BannerWrapper>
  );
};
