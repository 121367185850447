import { Button } from "../AboutMe/AboutMe.styles";
import * as S from "./Info.styles";

export const Info = () => {
  return (
    <S.InfoWrapper id="info">
      <h2>Como funciona a terapia?</h2>
      <S.InfoBodyWrapper>
        <h4>
          Você já se viu preso em um ciclo interminável de preocupações,
          ansiedade ou tristeza? Às vezes, a vida pode nos lançar desafios que
          parecem insuperáveis. É aí que a terapia entra em cena, como uma
          bússola confiável em meio à tempestade.
          <br />
          <br />
          Imagine finalmente sentir-se livre do peso que tem segurado seus
          ombros. Visualize uma mente clara e tranquila, pronta para enfrentar
          qualquer obstáculo que a vida apresente. Este é o poder transformador
          da terapia.
          <br />
          <br />
          Ao embarcar nessa jornada, você não estará sozinho. Vou te auxiliar a
          navegar pelas águas turbulentas dos seus pensamentos e emoções.
          <br />
          <br />
          Mas a terapia não é apenas sobre resolver problemas. É também sobre
          autodescoberta e crescimento pessoal. Ao explorar seus pensamentos
          mais profundos e padrões de comportamento, você abrirá portas para uma
          nova compreensão de si mesmo e do mundo ao seu redor.
          <br />
          <br />
          Não deixe que a angústia e a confusão dominem sua vida. Dê o primeiro
          passo em direção a uma existência mais plena e significativa. A
          terapia está esperando por você! Permita-se a experiência
          transformadora da terapia hoje mesmo.
        </h4>
        <Button target="_blank" href="https://wa.link/netv6h">
          Dê o primeiro passo!
        </Button>
      </S.InfoBodyWrapper>
    </S.InfoWrapper>
  );
};
