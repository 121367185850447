"use client";

import * as S from "./page.styles";
import { Banner } from "./components/Banner/Banner";
import { Header } from "./components/Header/Header";
import { AboutMe } from "./components/AboutMe/AboutMe";
import { Services } from "./components/Services/Services";
import { Info } from "./components/Info/Info";
import { useEffect, useState } from "react";
import { Place } from "./components/Place/place";
import { MdWhatsapp } from "react-icons/md";
import ReactGA from 'react-ga4';
import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setInterval(() => {
      if(process.env.REACT_APP_GA_ID){
        ReactGA.initialize(process.env.REACT_APP_GA_ID );
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div />;
  }

  return (
    <GoogleTagManagerProvider id={process.env.REACT_APP_GTM_ID}>
      <S.Container>
        <meta name="keywords" content="Psicologa, Áure Gomes, Terapia, TCC" />
        <meta name="title" content="Psicóloga Áurea Gomes" />
        <meta name="description" content="Psicologa clinica, atuando com teoria cognitivo comportamental CRP 15-7553, atendimento presencial e online em Maceió/AL." />
        <Header />
        <Banner />
        <AboutMe />
        <Services />
        <Info />
        <Place />
        <S.Spacer />
        <S.WhatsAppWrapper>
          <a target="_blank" href="https://wa.link/netv6h" rel="noreferrer">
            <MdWhatsapp color="white" />
          </a>
        </S.WhatsAppWrapper>
      </S.Container>
      </GoogleTagManagerProvider>
  );
}
