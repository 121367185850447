import styled from "styled-components";

// background: rgba(135,171,152,1);
export const ServicesWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  padding-top: 50px;
  width: 100%;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 50%;
  background: var(--secondary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
`;

export const ServiceTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--primary);
  margin-bottom: 1rem;
  font-family: colabero;
  @media (min-width: 425px) {
    font-size: 1.5rem;
  }
`;

export const CardsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  padding: 25px;
  padding-bottom: 100px;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: start;
  }
`;

export const Card = styled.div`
  display: flex;
  background: white;
  width: 345px;
  border-radius: 15px;
  align-content: center;
  justify-content: start;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 255px;

  @media (min-width: 360px) {
    width: 345px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  background: linear-gradient(
    135deg,
    rgba(115, 93, 68, 1) 52%,
    rgba(130, 68, 39, 1) 100%
  );
  h4 {
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    text-align: center;
    color: white;
  }
  @media (min-width: 425px) {
    h4 {
      font-size: 1.25rem;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  h5 {
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--primary);
    line-height: 1.25rem;
  }
  @media (min-width: 425px) {
    h5 {
      font-size: 1rem;
    }
  }
`;
